import React, { useState, useEffect, useRef } from 'react';

import ArrowIcon from './filters/ArrowIcon';
import ChevronIcon from './icons/ChevronIcon';
import SortIcon from './icons/SortIcon';

interface SortDropdownProps {
  value: string;
  options: { value: string; label: string }[];
  onChange: (value: string) => void;
}

const SortDropdownOptions: React.FC<{
  options: { value: string; label: string }[];
  selectedValue: string;
  onSelect: (value: string) => void;
  onClose: () => void;
}> = ({ options, onSelect, onClose, selectedValue }) => {
  const [tentativeValue, setTentativeValue] = useState(selectedValue);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className="absolute right-0 transform bg-white border rounded-3xl shadow-md z-50 border-1 border-gray-400 border-solid py-4 mt-0.5 text-sm text-gray-900 overflow-hidden"
      ref={dropdownRef}
    >
      {options.map((option) => (
        <div
          key={option.value}
          className={`py-2 px-4 cursor-pointer hover:bg-blue-100 ${
            option.value === tentativeValue ? 'bg-blue-100' : ''
          }`}
          onClick={() => {
            onSelect(option.value);
            onClose();
          }}
          onMouseEnter={() => setTentativeValue(option.value)}
          onMouseLeave={() => setTentativeValue(selectedValue)}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
};
export default function SortDropdown({
  value,
  options,
  onChange,
}: SortDropdownProps) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleOptionSelect = (value: string) => {
    const selected = options.find((option) => option.value === value);
    if (selected) {
      onChange(value);
      closeDropdown();
    }
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  return (
    <div className="relative">
      <button
        className="relative inline-block cursor-pointer rounded-xl p-0 m-0 border-none bg-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-white"
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <span
          className={`flex items-center p-4 text-sm font-semibold leading-none bg-white rounded-xl border border-solid border-gray-250 ${
            dropdownOpen
              ? 'bg-gray-800 text-white hover:bg-gray-900 border-none hover:border-none focus:border-none'
              : 'text-gray-900 hover:bg-gray-900 hover:text-white hover:border-0'
          }
           `}
          onClick={handleDropdownToggle}
        >
          <span className="flex-grow flex items-center gap-2 mr-2">
            <SortIcon type={dropdownOpen || isHovering ? 'light' : 'dark'} />
            <span className="flex-grow text-overflow-ellipsis whitespace-nowrap">
              Sort By: {options.find((option) => option.value === value)?.label}
            </span>
          </span>
          <ArrowIcon
            isCollapsed={!dropdownOpen}
            invalidSelectionMessage={''}
            className={isHovering || dropdownOpen ? 'fill-white' : ''}
          />
        </span>
      </button>
      {dropdownOpen && (
        <SortDropdownOptions
          options={options}
          selectedValue={value}
          onSelect={handleOptionSelect}
          onClose={closeDropdown}
        />
      )}
    </div>
  );
}
