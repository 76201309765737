import React from 'react';
import { connect } from 'react-redux';

import { setAudioFiltersChanged } from '../../../../Audio/actions/AudioActions';
import events from '../../../../Events/Events';
import RelatedCardClickEventFactory from '../../../../Events/RelatedCardClickEventFactory';
import RelatedCardDisplayEventFactory from '../../../../Events/RelatedCardDisplayEventFactory';
import { searchOrigins } from '../../../Shared/constants';
import { RelatedSearch, SearchFeatures } from '../../SearchTypes';
import {
  updateSearchOptionsAndRefresh,
  collapseMoreLikeThisDrawer,
} from '../../actions/SearchActions';
import { SelectedSearchFilterOptions } from '../../containers/MenuContainerInterfaces';
import {
  selectRelatedSearch,
  selectSearchFeatures,
  selectSearchFilterOptions,
} from '../../selectors/searchSelectors';
import RelatedSearchItem from './RelatedSearchItem';

import './RelatedSearchContainer.less';

type Props = {
  selectedSearchFilterOptions: SelectedSearchFilterOptions;
  relatedSearch: RelatedSearch[];
  collapseMoreLikeThisDrawer: typeof collapseMoreLikeThisDrawer;
  setAudioFiltersChanged: typeof setAudioFiltersChanged;
  updateSearchOptionsAndRefresh: typeof updateSearchOptionsAndRefresh;
  features: SearchFeatures;
};

class RelatedSearchContainer extends React.Component<Props> {
  relatedSearchTermsRef: HTMLDivElement;

  componentDidMount() {
    // We log the display event when this component is first mounted,
    // but not on subsequent renders (e.g. due to resizing the browser window).
    //
    // Remember: this component is NOT intended to be instantiated/rendered while a search is loading
    // (e.g. after the user triggers a new search by clicking on a related search term).
    const isVisible =
      this.relatedSearchTermsRef.getBoundingClientRect().width > 0;

    if (isVisible) {
      this.logRelatedSearchTermDisplay();
    }
  }

  handleSearchTermClicked(event, keyword, relativePosition) {
    this.logRelatedSearchTermClicked(keyword, relativePosition);
    this.searchTermClickedFireNewSearch(event, keyword);
  }

  logRelatedSearchTermClicked(keyword, relativePosition) {
    const event = RelatedCardClickEventFactory.click(
      this.props.selectedSearchFilterOptions.searchTerm,
      this.getCurrentRelatedSearchItems(),
      keyword,
      relativePosition
    );
    events.produce(event, events.visitorCookieId());
  }

  searchTermClickedFireNewSearch(event, keyword) {
    event.preventDefault();

    const newSearchOptions = this.props.selectedSearchFilterOptions.update({
      searchTerm: keyword,
      page: 1,
      isPagination: false,
      searchOrigin: searchOrigins.RELATED_SEARCH,
    }); // Go back to page 1 when the search term is changed

    this.props.setAudioFiltersChanged(true);
    this.props.updateSearchOptionsAndRefresh(newSearchOptions);
    this.props.collapseMoreLikeThisDrawer();
  }

  getCurrentRelatedSearchItems() {
    return this.props.relatedSearch.map(
      ({ relatedSearchTerm }) => relatedSearchTerm
    );
  }

  logRelatedSearchTermDisplay() {
    const availableTerms = this.getCurrentRelatedSearchItems();

    const event = RelatedCardDisplayEventFactory.display(
      this.props.selectedSearchFilterOptions.searchTerm,
      availableTerms
    );
    events.produce(event, events.visitorCookieId());
  }

  renderRelatedSearchItemSlides() {
    const { relatedSearch, features } = this.props;
    const slicedRelatedSearch = features.useRemainingSearchUI
      ? relatedSearch.slice(0, 5)
      : relatedSearch;

    return slicedRelatedSearch.map(({ relatedSearchTerm, url }, index) => (
      <RelatedSearchItem
        key={relatedSearchTerm}
        keyword={relatedSearchTerm}
        url={url}
        onSearchTermClicked={(event, keyword) =>
          this.handleSearchTermClicked(event, keyword, index + 1)
        }
        roundedDesign={true}
        isNewSearchUi={features.useRemainingSearchUI}
      />
    ));
  }

  render() {
    const useNewSearchUi = this.props.features.useRemainingSearchUI;
    const className = useNewSearchUi
      ? 'relatedSearchTerms flex align-center flex-wrap flex-auto overflow-visible justify-end m-0 space-x-2'
      : 'relatedSearchTerms flex align-center flex-wrap flex-auto my-4 overflow-visible';

    return (
      <>
        <div
          ref={(ref) => (this.relatedSearchTermsRef = ref)}
          className={className}
          data-testid="relatedSearchTerms"
        >
          {this.renderRelatedSearchItemSlides()}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    relatedSearch: selectRelatedSearch(state),
    selectedSearchFilterOptions: selectSearchFilterOptions(state),
    features: selectSearchFeatures(state),
  };
}

export default connect(mapStateToProps, {
  collapseMoreLikeThisDrawer,
  setAudioFiltersChanged,
  updateSearchOptionsAndRefresh,
})(RelatedSearchContainer);
